import { ThemeType } from '~/theme';
import { baseColors as colors } from './colors/baseColors';
import { getClampFullRange } from '../utils/getClampValue';
import { baseFontSettings } from './fontSettings/baseFontSettings';
import { pxToEm, pxToRem } from '../utils/pxToRelativeUnit';
import * as baseAnimations from './animations/baseAnimations';
import { transparentize } from 'color2k';

declare module '@emotion/react' {
    export interface Theme extends ThemeType {}
}

export const getBase = () => ({
    animations: baseAnimations,
    aspectRatios: {
        imageProduct: '2475 / 3262',
        imageModel: '2 / 3',
        productImageSingle: '1 / 1.49',
        productImageDouble: '1.37 / 1',
    },
    colors,
    fonts: {
        headline: '"neue-haas-grotesk-text","neue-haas-grotesk-text-fallback"',
        body: '"neue-haas-grotesk-text","neue-haas-grotesk-text-fallback"',
        mono: 'monospace',
    },
    fontWeights: {
        regular: 400,
        medium: 500,
        bold: 700,
    },
    fontSizes: baseFontSettings.sizes,
    fontSizesFluid: baseFontSettings.sizesFluid,
    lineHeights: {
        single: '1',
        compact: '1.2',
        base: '1.4',
        headline: '1.2',
    },
    gridConfig: {
        columnCount: {
            xs: 6,
            md: 12,
        },
        gap: getClampFullRange(24, 32),
        gutter: getClampFullRange(20, 96),
    },
    /**
     * @description
     * Default allowed characters per line, based on font-size variables.
     */
    maxWidthInCharacters: {
        xs: '70ch',
        sm: '70ch',
        md: '70ch',
        lg: '70ch',
        xl: '54ch',
        '2xl': '45ch',
        '3xl': '34ch',
        '4xl': '28ch',
        '5xl': '23ch',
        '6xl': '20ch',
    },
    letterSpacings: {
        xs: pxToEm('0.5px'),
        sm: pxToEm('0.75px'),
        md: pxToEm('1px'),
    },
    letterSpacingsFluid: {
        // Add more if needed
        md: getClampFullRange(0, 1), // 0px → 1px → 2.5px
    },
    /**
     * @description px value will be converted to rem
     * - 1 → 4px
     * - 2 → 8px
     * - 3 → 16px
     * - 4 → 24px
     * - 5 → 32px
     * - 6 → 48px
     * - 7 → 64px
     * - 8 → 80px
     * - 9 → 128px
     * - 10 → 160px
     */
    spaces: {
        '1': pxToRem(4),
        '2': pxToRem(8),
        '3': pxToRem(16),
        '4': pxToRem(24),
        '5': pxToRem(32),
        '6': pxToRem(48),
        '7': pxToRem(64),
        '8': pxToRem(80),
        '9': pxToRem(128),
        '10': pxToRem(160),
        '6px': pxToRem(6),
        '10px': pxToRem(10),
        '20px': pxToRem(20),
        '26px': pxToRem(26),
        '50px': pxToRem(50),
    },
    /**
     * @description raw spaces value
     * - 1 → 4
     * - 2 → 8
     * - 3 → 16
     * - 4 → 24
     * - 5 → 32
     * - 6 → 48
     * - 7 → 64
     * - 8 → 80
     * - 9 → 128
     * - 10 → 160
     */
    spacesRaw: {
        '1': 4,
        '2': 8,
        '3': 16,
        '4': 24,
        '5': 32,
        '6': 48,
        '7': 64,
        '8': 80,
        '9': 128,
        '10': 160,
        '6px': 6,
        '10px': 10,
        '20px': 20,
        '26px': 26,
        '50px': 50,
    },
    sizes: {
        // N20 Header
        N20headerHeight: 43,
        N20headerHeightDesktop: 63,

        // N21 Header
        N21headerHeight: 72,

        // N26 Height
        N26HeightMobile: 52,

        // Meta
        metaHeight: 37,
        metaHeightDesktop: 32,

        // Meta and navigation combined
        TotalHeaderHeight: 37 + 43,
        TotalHeaderHeightDesktop: 32 + 63,

        // Header checkout
        headerHeightCheckout: 93,

        // Logo
        logoHeaderWidth: 233,

        // Border
        borderWidth: 1,

        //Search
        searchMaxHeight: 394,

        // Filters
        desktopFilterDrawerWidth: 461,
        toogleButtonsPerRow: 4,

        // PLP Categories
        categoriesSidebarWidth: 276,

        // No Results Page
        noResultsMaxWidth: 1512,

        // Checkout - min headers height
        checkoutMinHeaderHeight: 130,

        // Content card min height eg. categorie cards heroes etc.
        contentCardMinHeight: 105,

        content: 'min(88vw, 1690px)',
    },
    borders: {
        border: `1px solid ${colors.dark}`,
        outline: `1px solid ${colors.dark}`,
        borderTransparent: `1px solid transparent`,
        borderNegative: `1px solid ${colors.red}`,
        radius: 0,
    },
    focus: {
        outlineOffset: '1px',
        outlineStyle: 'solid',
        outlineColor: colors.blue20,
        outlineWidth: '2px',
    },
    highlight: {
        background: colors.dark,
        color: colors.light,
        backgroundInverted: colors.light,
        colorInverted: colors.dark,
    },
    zIndices: {
        '1': 100,
        '2': 200,
        '3': 300,
        '4': 400,
        max: 999,
        drawer: 200,
        header: 300,
    },
    easings: {
        smoothEase: 'cubic-bezier(0.35, 0, 0.15, 1)',
    },
    opacity: {
        disabled: 0.5,
        overlay: 0.4,
    },
    buttons: {
        fontWeights: {
            sm: 400,
            md: 400,
            lg: 400,
        },
    },
    /**
     * @description Characteristics of the theme
     * e.g. FramedChalk has many lines.
     * - Use the line.asBorderStyle for lines that probably exists on all themes, like accordion lines
     * - Use the line.stylisticVisible for lines that should only exist on certain themes
     */
    traits: {
        // This is primarily used to position the filter menu on mobile, but could ostensibly be used for other cool
        // stuff as well (like actually making the headers sticky, for one).
        headerIsSticky: false,
        line: {
            width: 1,
            color: colors.dark,
            colorInverted: colors.light,
            colorAlternate: colors.dark,
            asBorderStyle: `1px solid ${colors.dark}`,
            stylisticVisible: true,
        },
        background: {
            default: colors.light,
            n11meta: colors.dark,
            n21mainMenu: colors.light,
        },
        contentCard: {
            textPosition: 'below',
        },
        image: {
            hover: {
                backgroundColorIdle: transparentize(colors.light, 1),
                backgroundColorActive: transparentize(colors.light, 0.8),
                boxShadowIdle: '',
                boxShadowActive: `0px 0px 0px 1px ${colors.dark} inset`,
            },
        },
        tooltip: {
            backgroundColor: colors.light,
            shade: 'paragraph',
            border: `1px solid ${colors.dark}`,
        },
        lineSpinner: {
            dark: colors.dark50,
            light: colors.light50,
        },
        sticky: {
            gutterTop: 89,
            gutterBottom: 24,
        },
        navigationIndicator: {
            background: colors.dark70,
        },
        mobileFacetButtons: {
            color: colors.dark70,
            borderColor: colors.light40,
        },
    },
    text: {
        color: {
            headline: colors.dark,
            paragraph: colors.dark70,
            headlineInverted: colors.light,
            paragraphInverted: colors.light30,
        },
        variants: {
            display1: baseFontSettings.sizesFluid['4xl'],
            display2: baseFontSettings.sizesFluid['3xl'],
            display3: baseFontSettings.sizesFluid['2xl'],
            display4: baseFontSettings.sizesFluid['lg'],
            display5: baseFontSettings.sizesFluid['md'],
            display6: baseFontSettings.sizesFluid['sm'],
            body: baseFontSettings.sizesFluid['md'],
            bodySm: baseFontSettings.sizesFluid['smMin'],
            bodyLg: baseFontSettings.sizesFluid['lg'],
            bodyXl: baseFontSettings.sizesFluid['xl'],
            caption: baseFontSettings.sizes['xs'],
            link: baseFontSettings.sizesFluid['md'],
            quote: baseFontSettings.sizes['2xl'],
            overline: baseFontSettings.sizes['xs'],
        },
        weights: {
            bold: 500,
            strong: 700,
        },
    },
    priceGroup: {
        colors: {
            default: colors.dark,
            original: colors.dark,
            discount: colors.discount,
        },
        weight: {
            default: 500,
            original: 500,
            discount: 500,
        },
    },
});
